require('./styles/style.css')

async function addChatbotElement(data) {

    const STYLES = {
        colors: {
            primary: data.chat_colors.primary ? data.chat_colors.primary : '#2c74e8',
            primary_hover: data.chat_colors.primary_hover ? data.chat_colors.primary_hover : '#1c64e8',
            user_message_bg: data.chat_colors.user_message_bg ? data.chat_colors.user_message_bg : '#007AFF'
        },
    }

    colorSwatchBtn = `
    <style>
    :root{
        --cjco-chatbot-primary: ${STYLES.colors.primary};
        --cjco-chatbot-primary-hover: ${STYLES.colors.primary_hover};
        --cjco-chatbot-user-message-bg: ${STYLES.colors.user_message_bg};
    }
    </style>
    <div id="chatbot" class="cjco_ai_chatbot z-50">
    <button id="chat-button"
      class="cjco_chatbot_bg_primary hover:cjco_chatbot_bg_primary w-16 h-16 rounded-full shadow-xl transition-all duration-200 flex items-center justify-center">
      <svg class="w-8 h-8 text-white" viewBox="0 0 28 32">
        <path fill="currentColor"
          d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107.819.819 0 00.113 1.212c.143.13 3.537 3.14 9.748 3.14 6.246 0 9.655-3.023 9.776-3.141a.817.817 0 00.086-1.21z">
        </path>
      </svg>
    </button>

    <div id="chat-window"
      class="cjco_ai_chatbot_window hidden bg-white rounded-[18px] shadow-2xl flex flex-col h-[680px] overflow-hidden">
      <div class="bg-white text-gray-800 p-6 flex flex-col">
        <div class="flex justify-between items-start">
          <div class="flex items-center">
            <div class="w-12 h-12 rounded-full overflow-hidden mr-3">
              <img src="${data.media.agent}" alt="${data.business_name} Agent Avatar"
                class="w-full h-full object-cover">
            </div>
            <div>
              <h3 class="font-bold text-[22px] text-gray-900 leading-tight" id="business-name">${data.business_name}</h3>
              <p class="text-[13px] text-gray-500 mt-0.5" id="business-tagline">${data.tagline}</p>
            </div>
          </div>
          <button id="close-chat" class="text-gray-400 hover:text-gray-600 p-1">
            <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>

    <div class="h-px bg-gray-200 -mx-6"></div>

      <div id="messages" class="flex-1 overflow-y-auto px-6 pb-12 pt-4 space-y-6">
        <!-- Messages will be inserted here dynamically -->
      </div>

      <div id="typing-indicator" class="hidden px-6 py-2">
        <div class="flex items-center space-x-2 text-gray-500 text-sm">
          <span class="font-medium typing-name"></span>
          <div class="flex items-center space-x-1">
            <div class="w-1.5 h-1.5 cjco_chatbot_bg_primary rounded-full animate-bounce opacity-60"></div>
            <div class="w-1.5 h-1.5 cjco_chatbot_bg_primary rounded-full animate-bounce opacity-60" style="animation-delay: 0.2s">
            </div>
            <div class="w-1.5 h-1.5 cjco_chatbot_bg_primary rounded-full animate-bounce opacity-60" style="animation-delay: 0.4s">
            </div>
          </div>
        </div>
      </div>

      <div class="p-4 bg-white border-t border-gray-200 relative z-10">
        <form id="chat-form" class="relative">
          <input type="text" id="user-input"
            class="cjco_chatbot_input w-full border border-gray-300 rounded-[24px] px-4 py-3 pr-12 focus:ring-1 text-gray-800 placeholder-gray-500 text-[15px]"
            placeholder="Send a message..." autocomplete="off" required>
          <button type="submit"
            class="absolute right-2 top-1/2 transform -translate-y-1/2 cjco_chatbot_text_primary p-2">
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"></path>
            </svg>
          </button>
        </form>
      </div>
    </div>
  </div>`;

  document.body.insertAdjacentHTML("beforeend", colorSwatchBtn);

}

async function initChatbot(data){

    // Business Information Configuration
    const BUSINESS_INFO = {
        name: data.business_name,
        tagline: "Helping Children Communicate",
        agent: data.agent,
        language: "Australian/British English",
        phone: data.phone,
        email: data.email,
        website: "https://www.speechclinic.com.au/",
        overview: "Speech Clinic is a telehealth and mobile speech pathology service dedicated to empowering children and young people aged 0-18 across Australia.",
        usp: "Founded by Dr Lauren Crumlish, published author and academic.",
        mainLocation: "Brisbane",
        locations: [
            "Mobile Services around Redlands and Bayside",
            "Australia-wide (Online Telehealth Therapy)"
        ],
        operatingHours: "Monday-Friday 9am-5pm",
        pricing: {
            initial: 193,
            followUp: 193,
            assessments: "Starting from $250"
        },
        welcomeMessages: {
            greeting: data.welcomeMessages.greeting ? data.welcomeMessages.greeting : "Hello!",
            introduction: data.welcomeMessages.introduction ? data.welcomeMessages.introduction : "here! 👋",
            prompt: data.welcomeMessages.prompt ? data.welcomeMessages.prompt : "Let me know if I can help out or if you have any questions."
        }
    };

    // Configuration Object
    const CONFIG = {
        LOGGING_ENDPOINT: 'https://mythicalapp.com/api.php?action=log_error',
        INACTIVITY_TIMEOUT: 18000,
        INITIAL_POPUP_DELAY: 5000,
        TYPING_SPEED: 50, // ms per character
        MAX_TYPING_DELAY: 3000,
        APPROVED_MEDIA: {
            GREETING: data.media.greeting ? data.media.greeting : 'https://media.tenor.com/dj2wXFsd6ukAAAAM/hello-hi2.gif',
            AGENT: data.media.agent ? data.media.agent : 'https://cdn.cjco.app/wp-content/uploads/avatar1.jpg',
            WAVE: data.media.wave ? data.media.wave : 'https://media.tenor.com/dj2wXFsd6ukAAAAM/hello-hi.gif',
            THINKING: data.media.thinking ? data.media.thinking : 'https://media.tenor.com/VWbwqhXAS7gAAAAM/hmmm-thinking.gif'
        },
        CHAT_COLORS: {
            PRIMARY: data.chat_colors.primary,
            PRIMARY_HOVER: data.chat_colors.primary_hover,
            USER_MESSAGE_BG: data.chat_colors.user_message_bg
        },
        CHAT_WINDOW: {
            WIDTH: '380px',
            HEIGHT: '680px',
            BORDER_RADIUS: '18px'
        },
        AI_MODEL: 'gpt-4',
        MESSAGE_SPLIT: {
            MIN_LENGTH_FOR_SPLIT: 100,
            MAX_SEGMENTS: 3,
            DELAY_BETWEEN: 1000,
        }
    };

    // State Management
    let lastInteraction = Date.now();
    let hasConverted = false;
    let isConversationEnded = false;
    let conversationHistory = []; // Managed server-side
    let leadState = {
        name: null,
        email: null,
        phone: null,
        isComplete: false,
        dynamicInfo: {
            location: null,
            service: null,
            age: null,
            postcode: null,
            gender: null,
            urgency: null,
            availability: null,
            budget: null,
            referralSource: null,
            previousTherapy: null,
            diagnosis: null,
            medicare: null,
            ndis: null,
            insurance: null,
            preferredLocation: null,
            therapistPreference: null,
            languages: null,
            culturalConsiderations: null,
            otherDetails: {}
        }
    };

    // DOM Elements
    const chatButton = document.getElementById('chat-button');
    const chatWindow = document.getElementById('chat-window');
    const closeChat = document.getElementById('close-chat');
    const messagesContainer = document.getElementById('messages');
    const chatForm = document.getElementById('chat-form');
    const userInput = document.getElementById('user-input');
    const typingIndicator = document.getElementById('typing-indicator');

    // Toggle Chat Visibility
    function toggleChat(show = true) {
        if (show) {
            chatButton.classList.add('hidden');
            chatWindow.classList.remove('hidden');
            lastInteraction = Date.now();
        } else {
            chatButton.classList.remove('hidden');
            chatWindow.classList.add('hidden');
        }
    }

    // Add Message to Chat
    function addMessage(text, isBot = false, mediaUrl = null) {
        const messageDiv = document.createElement('div');
        messageDiv.className = `flex ${isBot ? 'justify-start' : 'justify-end'} items-end space-x-2`;
        
        if (isBot) {
            const avatarDiv = document.createElement('div');
            avatarDiv.className = 'w-8 h-8 rounded-full overflow-hidden flex-shrink-0';
            avatarDiv.innerHTML = `
                <img 
                    src="${CONFIG.APPROVED_MEDIA.AGENT}" 
                    alt="Agent Avatar"
                    class="w-full h-full object-cover"
                >`;
            messageDiv.appendChild(avatarDiv);
        }
        
        const messageContent = document.createElement('div');
        messageContent.className = `max-w-[80%] rounded-2xl px-4 py-3 ${
            isBot 
                ? 'bg-white text-gray-800 border border-gray-200 shadow-sm rounded-bl-none' 
                : `cjco_chatbot_bg_user_message text-white rounded-br-none`
        }`;

        if (mediaUrl) {
            const mediaElement = document.createElement('img');
            mediaElement.src = mediaUrl;
            mediaElement.alt = 'Chat Media';
            mediaElement.className = 'mb-2 rounded-lg w-full max-w-[240px] h-auto';
            mediaElement.onload = () => {
                messagesContainer.scrollTop = messagesContainer.scrollHeight;
            };
            messageContent.appendChild(mediaElement);
        }

        const textElement = document.createElement('div');
        textElement.textContent = text;
        textElement.className = `text-[15px] leading-relaxed ${isBot ? 'text-gray-800' : 'text-white'}`;
        messageContent.appendChild(textElement);
        
        messageDiv.appendChild(messageContent);
        
        const messageWrapper = document.createElement('div');
        messageWrapper.className = 'mb-4';
        messageWrapper.appendChild(messageDiv);
        
        messagesContainer.appendChild(messageWrapper);
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
        
        lastInteraction = Date.now();
    }

    // Show Typing Indicator
    function showTypingIndicator() {
        typingIndicator.style.display = 'block';
        const typingName = document.querySelector('.typing-name');
        typingName.textContent = `${BUSINESS_INFO.agent} is typing`;
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }

    // Hide Typing Indicator
    function hideTypingIndicator() {
        typingIndicator.style.display = 'none';
    }

    // Function to send user message to the backend
    async function sendMessage(message) {
        try {
            
            showTypingIndicator();
            const response = await fetch('https://mythicalapp.com/api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify( message )
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok (${response.status})`);
            }

            const data = await response.json();
            hideTypingIndicator();

            if (data.success) {
                addMessage(data.reply, true, data.mediaUrl || null);
            } else {
                addMessage("I'm sorry, something went wrong. Please try again later.", true);
            }
        } catch (error) {
            console.error('Error sending message to API:', error);
            hideTypingIndicator();
            addMessage("I'm sorry, I'm having trouble connecting right now. Please try again later.", true);
            
            // Log the error to the server
            logError({
                message: "Error sending message to API",
                error: error.message,
                timestamp: new Date().toISOString(),
                userMessage: message
            });
        }
    }

    // Function to log errors to the server
    async function logError(errorDetails) {
        try {
            const response = await fetch(CONFIG.LOGGING_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(errorDetails)
            });

            if (!response.ok) {
                console.error(`Failed to log error to server (Status: ${response.status})`);
            }
        } catch (err) {
            console.error('Failed to send error log to server:', err);
        }
    }

    // Handle Form Submission
    chatForm.addEventListener('submit', async (e) => {
        e.preventDefault();
        
        // Early check for ended conversation
        if (isConversationEnded) {
            console.log('Conversation ended, ignoring submission');
            return;
        }

        const message = userInput.value.trim();

        let newMessage = {};
        newMessage.message = message;
        newMessage.prompt = data.prompt;

        if (message) {
            addMessage(message, false);
            userInput.value = '';
            await sendMessage(newMessage);
        }
    });

    // Add Quick Action Buttons
    function addQuickActionButtons() {
        const buttonsDiv = document.createElement('div');
        buttonsDiv.className = 'flex justify-center space-x-3 my-4 opacity-0 transform translate-y-4'; // Added opacity-0 and transform
        
        const buttonClasses = `
            bg-gray-100
            text-gray-800
            px-4
            py-2
            rounded-full
            text-sm
            font-medium
            transition-all
            duration-300
            hover:bg-gray-200
            hover:shadow-md
            flex 
            items-center 
            space-x-1.5
            border border-gray-200
        `;
        
        const callButton = document.createElement('button');
        callButton.className = buttonClasses;
        callButton.innerHTML = `
            <svg class="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>
            <span>Call Us</span>
        `;
        callButton.onclick = () => window.location.href = `tel:${BUSINESS_INFO.phone}`;
        
        const emailButton = document.createElement('button');
        emailButton.className = buttonClasses;
        emailButton.innerHTML = `
            <svg class="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            <span>Email Us</span>
        `;
        emailButton.onclick = () => window.location.href = `mailto:${BUSINESS_INFO.email}`;
        
        buttonsDiv.appendChild(callButton);
        buttonsDiv.appendChild(emailButton);
        messagesContainer.appendChild(buttonsDiv);
        
        // Trigger animation after a small delay
        requestAnimationFrame(() => {
            buttonsDiv.style.transition = 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)';
            buttonsDiv.style.opacity = '1';
            buttonsDiv.style.transform = 'translateY(0)';
        });
        
        // Scroll to bottom after adding buttons
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }

    // Initial Popup with Welcome Messages
    setTimeout(() => {
        if (!hasConverted) {
            toggleChat(true);
            
            // First message with just the greeting GIF
            showTypingIndicator();
            setTimeout(() => {
                addMessage(
                    "",  // Empty text, just the GIF
                    true,
                    CONFIG.APPROVED_MEDIA.GREETING
                );
                hideTypingIndicator();
                
                // Second message with greeting text from config
                setTimeout(() => {
                    showTypingIndicator();
                    setTimeout(() => {
                        addMessage(`${BUSINESS_INFO.welcomeMessages.greeting} ${BUSINESS_INFO.agent} ${BUSINESS_INFO.welcomeMessages.introduction}`, true);
                        hideTypingIndicator();
                        
                        // Third message after 3 seconds
                        setTimeout(() => {
                            showTypingIndicator();
                            setTimeout(() => {
                                addMessage(BUSINESS_INFO.welcomeMessages.prompt, true);
                                hideTypingIndicator();
                                
                                // Add buttons after another 1 second
                                setTimeout(() => {
                                    addQuickActionButtons();
                                    setTimeout(() => {
                                        messagesContainer.scrollTop = messagesContainer.scrollHeight;
                                    }, 100);
                                }, 1000);
                            }, 1500);
                        }, 3000);
                        
                    }, 1500);
                }, 200);
            }, 1500);
        }
    }, CONFIG.INITIAL_POPUP_DELAY);

    // Bump State for Proactive Features
    const BUMP_STATE = {
        lastBumpTime: 0,
        minTimeBetweenBumps: 60000, // 60 seconds
        maxBumps: 3,
        currentBumpIndex: 0,
        bumpSequence: [
            {
                message: "Hello?",
                delay: 30000 // 30 seconds
            },
            {
                message: "You still there?",
                delay: 20000 // 20 seconds
            },
            {
                message: "No rush, whenever you're back, I'm here to help 🙌",
                delay: 40000 // 40 seconds
            }
        ]
    };

    // Set Interval for Bump Messages
    setInterval(async () => {
        // Skip checks if conversation is ended or chat is hidden
        if (
            isConversationEnded || 
            chatWindow.classList.contains('hidden') || 
            hasConverted
        ) {
            return;
        }

        const timeSinceLastBump = Date.now() - BUMP_STATE.lastBumpTime;

        // Get current bump configuration
        const currentBump = BUMP_STATE.bumpSequence[BUMP_STATE.currentBumpIndex];
        if (!currentBump) return;

        if (timeSinceLastBump >= currentBump.delay) {
            // Show typing indicator and send message with proper delays
            showTypingIndicator();
            await new Promise(resolve => setTimeout(resolve, 2000)); // Show typing for 2 seconds
            addMessage(currentBump.message, true);
            hideTypingIndicator();

            // Update state
            BUMP_STATE.lastBumpTime = Date.now();
            BUMP_STATE.currentBumpIndex++;

            if (BUMP_STATE.currentBumpIndex >= BUMP_STATE.bumpSequence.length) {
                clearInterval(this);
            }
        }

    }, 5000); // Check every 5 seconds

    // Event Listeners for Chat Buttons
    chatButton.addEventListener('click', () => toggleChat(true));
    closeChat.addEventListener('click', () => toggleChat(false));

}

module.exports = {
    chatbot: function (data) {
        new addChatbotElement(data);
        new initChatbot(data);
    }
};